import { useState } from "react";
import { Avatar } from "antd";
import { getColorByName } from "../../../utils/service";

const ImageWithHandler = ({ title, titleSize = "0.8125rem", src, ...props }) => {
  const [hasError, setHasError] = useState(!src);

  const handleError = () => {
    setHasError(true);
  };

  if (!hasError) return <Avatar src={src} {...props} onError={handleError} />;
  else
    return (
      <Avatar
        {...props}
        alt=""
        style={{
          backgroundColor: getColorByName(title),
          verticalAlign: "middle",
          fontWeight: "bold",
          fontSize: titleSize,
        }}>
        {title}
      </Avatar>
    );
};

export default ImageWithHandler;
