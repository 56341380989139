import "./index.css";
import { Flex } from "antd";
import { useNavigate } from "react-router-dom";
import { pathData } from "../../../../../utils/routes";
import { useSearch } from "../../../../../components/contexts/SearchContext";
import Rating from "../../../../../components/common/Rating";
import useWindowSize from "../../../../../components/hooks/useWindowSize";

const ItemPerfume = ({ data, ...props }) => {
  const navigate = useNavigate();
  const { isMobileView } = useWindowSize();

  const { setShowSearchMobile } = useSearch();
  const handleClick = () => {
    if (isMobileView) setShowSearchMobile(false);
    navigate(`${pathData.perfume}/${data.id}`);
  };

  return (
    <Flex {...props} vertical gap={0} className="vfra-explore-card-popular" onClick={handleClick}>
      <Flex justify="center">
        <img loading="lazy" className="vfra-explore-card-popular-img" alt={data.name} src={data.image_url} />
      </Flex>

      <h1 className="one-line-ellipsis vfra-explore-card-popular-title">{data.name}</h1>
      <Rating value={data.rating} />
    </Flex>
  );
};

export default ItemPerfume;
