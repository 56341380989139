export const optionDataVotes = [
  {
    id: "longevity",
    name: "Longevity",
    list: [
      {
        title: "enduring",
        value: 4,
        isActive: false,
      },
      {
        title: "long",
        value: 3,
        isActive: false,
      },
      {
        title: "moderate",
        value: 2,
        isActive: false,
      },
      {
        title: "short",
        value: 1,
        isActive: false,
      },
      {
        title: "faint",
        value: 0,
        isActive: true,
      },
    ],
  },
  {
    id: "sillage",
    name: "Sillage",
    list: [
      {
        title: "powerful",
        value: 3,
        isActive: true,
      },
      {
        title: "strong",
        value: 2,
        isActive: false,
      },
      {
        title: "soft",
        value: 1,
        isActive: false,
      },
      {
        title: "close",
        value: 0,
        isActive: false,
      },
    ],
  },
  {
    id: "price_value",
    name: "Price value",
    list: [
      {
        title: "excellent",
        value: 4,
        isActive: false,
      },
      {
        title: "good",
        value: 3,
        isActive: false,
      },
      {
        title: "fair",
        value: 2,
        isActive: true,
      },
      {
        title: "costly",
        value: 1,
        isActive: false,
      },
      {
        title: "not worthy",
        value: 0,
        isActive: false,
      },
    ],
  },
];

export const initDataVotes = {
  rate: null,
  longevity: null,
  sillage: null,
  price_value: null,
  genders: {
    male: 0,
    female: 0,
    unisex: 0,
  },
  seasons: {
    winter: 0,
    spring: 0,
    summer: 0,
    fall: 0,
  },
  weathers: {
    day: 0,
    night: 0,
  },
};

export const genderOptions = [
  {
    label: "male",
    value: "male",
  },
  {
    label: "unisex",
    value: "unisex",
  },
  {
    label: "female",
    value: "female",
  },
];

export const longevityOptions = [
  {
    key: 0,
    title: "faint",
  },
  {
    key: 1,
    title: "short",
  },
  {
    key: 2,
    title: "moderate",
  },
  {
    key: 3,
    title: "long",
  },
  {
    key: 4,
    title: "enduring",
  },
];

export const sillageOptions = [
  {
    key: 0,
    title: "close",
  },
  {
    key: 1,
    title: "soft",
  },
  {
    key: 2,
    title: "strong",
  },
  {
    key: 3,
    title: "powerful",
  },
];

export const priceOptions = [
  {
    key: 0,
    title: "not worthy",
  },
  {
    key: 1,
    title: "costly",
  },
  {
    key: 2,
    title: "fair",
  },
  {
    key: 3,
    title: "good",
  },
  {
    key: 4,
    title: "excellent",
  },
];

export const popular_perfumes = [
  {
    id: "aventus-creed",
    name: "Aventus Creed for men",
    image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.9828.jpg",
    brand_id: "creed",
    brand_name: "Creed",
    brand_logo: "https://img.perfumerates.com/hizme/rzjeaijii/i.50.jpg",
    rating: 4.35,
  },
  {
    id: "reflection-man-amouage",
    name: "Reflection Man Amouage for men",
    image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.920.jpg",
    brand_id: "amouage",
    brand_name: "Amouage",
    brand_logo: "https://img.perfumerates.com/hizme/rzjeaijii/i.122.jpg",
    rating: 4.37,
  },
  {
    id: "oud-wood-tom-ford",
    name: "Oud Wood Tom Ford for women and men",
    image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.1826.jpg",
    brand_id: "tom-ford",
    brand_name: "Tom Ford",
    brand_logo: "https://img.perfumerates.com/hizme/rzjeaijii/i.139.jpg",
    rating: 4.33,
  },
  {
    id: "elysium-pour-homme-parfum-roja-dove",
    name: "Elysium Pour Homme Parfum Roja Dove for men",
    image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.47480.jpg",
    brand_id: "roja-dove",
    brand_name: "Roja Dove",
    brand_logo: "https://img.perfumerates.com/hizme/rzjeaijii/i.626.jpg",
    rating: 4.44,
  },
  {
    id: "good-girl-gone-bad-by-kilian",
    name: "Good Girl Gone Bad By Kilian for women",
    image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.15924.jpg",
    brand_id: "by-kilian",
    brand_name: "By Kilian",
    brand_logo: "https://img.perfumerates.com/hizme/rzjeaijii/i.491.jpg",
    rating: 3.82,
  },
  {
    id: "oud-satin-mood-extrait-de-parfum-maison-francis-kurkdjian",
    name: "Oud Satin Mood Extrait de parfum Maison Francis Kurkdjian for women and men",
    image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.48463.jpg",
    brand_id: "maison-francis-kurkdjian",
    brand_name: "Maison Francis Kurkdjian",
    brand_logo: "https://img.perfumerates.com/hizme/rzjeaijii/i.770.jpg",
    rating: 4.36,
  },
  {
    id: "hacivat-nishane",
    name: "Hacivat Nishane for women and men",
    image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.44174.jpg",
    brand_id: "nishane",
    brand_name: "Nishane",
    brand_logo: "https://img.perfumerates.com/hizme/rzjeaijii/i.2232.jpg",
    rating: 4.36,
  },
  {
    id: "coco-mademoiselle-chanel",
    name: "Coco Mademoiselle Chanel for women",
    image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.611.jpg",
    brand_id: "chanel",
    brand_name: "Chanel",
    brand_logo: "https://img.perfumerates.com/hizme/rzjeaijii/i.30.jpg",
    rating: 4.12,
  },
  {
    id: "tam-dao-eau-de-parfum-diptyque",
    name: "Tam Dao Eau de Parfum Diptyque for women and men",
    image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.49104.jpg",
    brand_id: "diptyque",
    brand_name: "Diptyque",
    brand_logo: "https://img.perfumerates.com/hizme/rzjeaijii/i.421.jpg",
    rating: 4.16,
  },
  {
    id: "afternoon-swim-louis-vuitton",
    name: "Afternoon Swim Louis Vuitton for women and men",
    image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.53947.jpg",
    brand_id: "louis-vuitton",
    brand_name: "Louis Vuitton",
    brand_logo: "https://img.perfumerates.com/hizme/rzjeaijii/i.2747.jpg",
    rating: 4.33,
  },
  {
    id: "another-13-le-labo",
    name: "Another 13 Le Labo for women and men",
    image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.10131.jpg",
    brand_id: "le-labo",
    brand_name: "Le Labo",
    brand_logo: "https://img.perfumerates.com/hizme/rzjeaijii/i.403.jpg",
    rating: 4.02,
  },
];

export const random_popular_perfumes = popular_perfumes.sort(() => Math.random() - 0.5);

export const countries = [
  {
    name: "Australia",
    img: "https://flagcdn.com/w80/au.png",
  },
  {
    name: "Brazil",
    img: "https://flagcdn.com/w80/br.png",
  },
  {
    name: "Canada",
    img: "https://flagcdn.com/w80/ca.png",
  },
  {
    name: "France",
    img: "https://flagcdn.com/w80/fr.png",
  },
  {
    name: "Germany",
    img: "https://flagcdn.com/w80/de.png",
  },
  {
    name: "Greece",
    img: "https://flagcdn.com/w80/gr.png",
  },
  {
    name: "Italy",
    img: "https://flagcdn.com/w80/it.png",
  },
  {
    name: "Japan",
    img: "https://flagcdn.com/w80/jp.png",
  },
  {
    name: "Netherlands",
    img: "https://flagcdn.com/w80/nl.png",
  },
  {
    name: "Oman",
    img: "https://flagcdn.com/w80/om.png",
  },
  {
    name: "Poland",
    img: "https://flagcdn.com/w80/pl.png",
  },
  {
    name: "Russia",
    img: "https://flagcdn.com/w80/ru.png",
  },
  {
    name: "Saudi Arabia",
    img: "https://flagcdn.com/w80/sa.png",
  },
  {
    name: "Spain",
    img: "https://flagcdn.com/w80/es.png",
  },
  {
    name: "Sweden",
    img: "https://flagcdn.com/w80/se.png",
  },
  {
    name: "Switzerland",
    img: "https://flagcdn.com/w80/ch.png",
  },
  {
    name: "Thailand",
    img: "https://flagcdn.com/w80/th.png",
  },
  {
    name: "Turkey",
    img: "https://flagcdn.com/w80/tr.png",
  },
  {
    name: "United Arab Emirates",
    img: "https://flagcdn.com/w80/ae.png",
  },
  {
    name: "United Kingdom",
    img: "https://flagcdn.com/w80/gb.png",
  },
  {
    name: "United States",
    img: "https://flagcdn.com/w80/us.png",
  },
  {
    name: "Argentina",
    img: "https://flagcdn.com/w80/ar.png",
  },
  {
    name: "Armenia",
    img: "https://flagcdn.com/w80/am.png",
  },
  {
    name: "Aruba",
    img: "https://flagcdn.com/w80/aw.png",
  },
  {
    name: "Austria",
    img: "https://flagcdn.com/w80/at.png",
  },
  {
    name: "Azerbaijan",
    img: "https://flagcdn.com/w80/az.png",
  },
  {
    name: "Bahrain",
    img: "https://flagcdn.com/w80/bh.png",
  },
  {
    name: "Bangladesh",
    img: "https://flagcdn.com/w80/bd.png",
  },
  {
    name: "Barbados",
    img: "https://flagcdn.com/w80/bb.png",
  },
  {
    name: "Belarus",
    img: "https://flagcdn.com/w80/by.png",
  },
  {
    name: "Belgium",
    img: "https://flagcdn.com/w80/be.png",
  },
  {
    name: "Belorussia",
    img: "https://via.placeholder.com/64/fff?text=Belorussia",
  },
  {
    name: "Bermuda",
    img: "https://flagcdn.com/w80/bm.png",
  },
  {
    name: "Bulgaria",
    img: "https://flagcdn.com/w80/bg.png",
  },
  {
    name: "Cabo Verde",
    img: "https://via.placeholder.com/64/fff?text=Cabo Verde",
  },
  {
    name: "Cayman Islands",
    img: "https://flagcdn.com/w80/ky.png",
  },
  {
    name: "Chile",
    img: "https://flagcdn.com/w80/cl.png",
  },
  {
    name: "China",
    img: "https://flagcdn.com/w80/cn.png",
  },
  {
    name: "Colombia",
    img: "https://flagcdn.com/w80/co.png",
  },
  {
    name: "Cote D’Ivoire",
    img: "https://via.placeholder.com/64/fff?text=Cote D’Ivoire",
  },
  {
    name: "Croatia",
    img: "https://flagcdn.com/w80/hr.png",
  },
  {
    name: "Cuba",
    img: "https://flagcdn.com/w80/cu.png",
  },
  {
    name: "Cyprus",
    img: "https://flagcdn.com/w80/cy.png",
  },
  {
    name: "Czech Republic",
    img: "https://flagcdn.com/w80/cz.png",
  },
  {
    name: "Denmark",
    img: "https://flagcdn.com/w80/dk.png",
  },
  {
    name: "Dominican Republic",
    img: "https://flagcdn.com/w80/do.png",
  },
  {
    name: "Dubai",
    img: "https://via.placeholder.com/64/fff?text=Dubai",
  },
  {
    name: "Ecuador",
    img: "https://flagcdn.com/w80/ec.png",
  },
  {
    name: "Egypt",
    img: "https://flagcdn.com/w80/eg.png",
  },
  {
    name: "Estonia",
    img: "https://flagcdn.com/w80/ee.png",
  },
  {
    name: "Finland",
    img: "https://flagcdn.com/w80/fi.png",
  },
  {
    name: "Georgia",
    img: "https://flagcdn.com/w80/ge.png",
  },
  {
    name: "Ghana",
    img: "https://flagcdn.com/w80/gh.png",
  },
  {
    name: "Guatemala",
    img: "https://flagcdn.com/w80/gt.png",
  },
  {
    name: "Hong Kong",
    img: "https://flagcdn.com/w80/hk.png",
  },
  {
    name: "Hungary",
    img: "https://flagcdn.com/w80/hu.png",
  },
  {
    name: "Iceland",
    img: "https://flagcdn.com/w80/is.png",
  },
  {
    name: "India",
    img: "https://flagcdn.com/w80/in.png",
  },
  {
    name: "Indonesia",
    img: "https://flagcdn.com/w80/id.png",
  },
  {
    name: "Iran",
    img: "https://flagcdn.com/w80/ir.png",
  },
  {
    name: "Ireland",
    img: "https://flagcdn.com/w80/ie.png",
  },
  {
    name: "Israel",
    img: "https://flagcdn.com/w80/il.png",
  },
  {
    name: "Jordan",
    img: "https://flagcdn.com/w80/jo.png",
  },
  {
    name: "Kazakhstan",
    img: "https://flagcdn.com/w80/kz.png",
  },
  {
    name: "Kenya",
    img: "https://flagcdn.com/w80/ke.png",
  },
  {
    name: "Kuwait",
    img: "https://flagcdn.com/w80/kw.png",
  },
  {
    name: "Latvia",
    img: "https://flagcdn.com/w80/lv.png",
  },
  {
    name: "Lebanon",
    img: "https://flagcdn.com/w80/lb.png",
  },
  {
    name: "Liberia",
    img: "https://flagcdn.com/w80/lr.png",
  },
  {
    name: "Lithuania",
    img: "https://flagcdn.com/w80/lt.png",
  },
  {
    name: "Luxembourg",
    img: "https://flagcdn.com/w80/lu.png",
  },
  {
    name: "Malaysia",
    img: "https://flagcdn.com/w80/my.png",
  },
  {
    name: "Malta",
    img: "https://flagcdn.com/w80/mt.png",
  },
  {
    name: "Mexico",
    img: "https://flagcdn.com/w80/mx.png",
  },
  {
    name: "Moldavia",
    img: "https://via.placeholder.com/64/fff?text=Moldavia",
  },
  {
    name: "Monaco",
    img: "https://flagcdn.com/w80/mc.png",
  },
  {
    name: "Morocco",
    img: "https://flagcdn.com/w80/ma.png",
  },
  {
    name: "Myanmar",
    img: "https://flagcdn.com/w80/mm.png",
  },
  {
    name: "New Zealand",
    img: "https://flagcdn.com/w80/nz.png",
  },
  {
    name: "Nigeria",
    img: "https://flagcdn.com/w80/ng.png",
  },
  {
    name: "Norway",
    img: "https://flagcdn.com/w80/no.png",
  },
  {
    name: "Pakistan",
    img: "https://flagcdn.com/w80/pk.png",
  },
  {
    name: "Peru",
    img: "https://flagcdn.com/w80/pe.png",
  },
  {
    name: "Philippines",
    img: "https://flagcdn.com/w80/ph.png",
  },
  {
    name: "Portugal",
    img: "https://flagcdn.com/w80/pt.png",
  },
  {
    name: "Puerto Rico",
    img: "https://flagcdn.com/w80/pr.png",
  },
  {
    name: "Qatar",
    img: "https://flagcdn.com/w80/qa.png",
  },
  {
    name: "Romania",
    img: "https://flagcdn.com/w80/ro.png",
  },
  {
    name: "Rwanda",
    img: "https://flagcdn.com/w80/rw.png",
  },
  {
    name: "Serbia",
    img: "https://flagcdn.com/w80/rs.png",
  },
  {
    name: "Seychelles",
    img: "https://flagcdn.com/w80/sc.png",
  },
  {
    name: "Singapore",
    img: "https://flagcdn.com/w80/sg.png",
  },
  {
    name: "Slovakia",
    img: "https://flagcdn.com/w80/sk.png",
  },
  {
    name: "South Africa",
    img: "https://flagcdn.com/w80/za.png",
  },
  {
    name: "South Korea",
    img: "https://via.placeholder.com/64/fff?text=South Korea",
  },
  {
    name: "Sri Lanka",
    img: "https://flagcdn.com/w80/lk.png",
  },
  {
    name: "St. Barts",
    img: "https://via.placeholder.com/64/fff?text=St. Barts",
  },
  {
    name: "Tahiti",
    img: "https://via.placeholder.com/64/fff?text=Tahiti",
  },
  {
    name: "Taiwan",
    img: "https://flagcdn.com/w80/tw.png",
  },
  {
    name: "Tenerife",
    img: "https://via.placeholder.com/64/fff?text=Tenerife",
  },
  {
    name: "Ukraine",
    img: "https://flagcdn.com/w80/ua.png",
  },
  {
    name: "Uzbekistan",
    img: "https://flagcdn.com/w80/uz.png",
  },
  {
    name: "Vietnam",
    img: "https://flagcdn.com/w80/vn.png",
  },
  {
    name: "Wales",
    img: "https://via.placeholder.com/64/fff?text=Wales",
  },
  {
    name: "Zambia",
    img: "https://flagcdn.com/w80/zm.png",
  },
];
