import React, { Suspense } from "react";
import { Flex } from "antd";
import useWindowSize from "../../../components/hooks/useWindowSize";
import PopularPerfumes from "./PopularPerfumes";
import TopReviewers from "./TopReviewers";

const TopComments = React.lazy(() => import("./TopComments"));
const PostExplore = React.lazy(() => import("./PostExplore"));

const ScentubExplore = () => {
  const { isMobileView } = useWindowSize();

  return (
    <Flex vertical gap={isMobileView ? "0" : "1.5rem"} style={{ marginBottom: "1rem" }}>
      <PopularPerfumes />
      <TopReviewers />

      <Suspense>
        <TopComments />
      </Suspense>

      <Suspense>
        <PostExplore />
      </Suspense>
    </Flex>
  );
};

export default ScentubExplore;
