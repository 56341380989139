export const pathData = {
  home: "",
  explore: "/explore",
  postDetail: "/post-detail",
  search: "/search",
  searchDetail: "/search-detail",
  perfume: "/perfume",
  brand: "/brand",
  profile: "/profile",
  relatePost: "/relate-post",
  checkout: "/checkout",
};

export const pathMenus = [
  {
    id: "post-detail",
    path: pathData.postDetail,
    label: "Post detail",
    visible: false,
  },
  {
    id: "home",
    path: pathData.home,
    label: "Home",
    visible: true,
  },
  {
    id: "explore",
    path: pathData.explore,
    label: "Explore",
    visible: true,
  },
  {
    id: "search-detail",
    path: pathData.searchDetail,
    label: "Search detail",
    visible: false,
  },
  {
    id: "search",
    path: pathData.search,
    label: "Search",
    visible: false,
  },
  {
    id: "perfume",
    path: pathData.perfume,
    label: "Perfume",
    visible: false,
  },
  {
    id: "brand",
    path: pathData.brand,
    label: "Brand",
    visible: false,
  },
];
